// https://cloudinary.com/documentation/transformation_reference

export interface CloudinaryProps {
  crop?: "fill" | "fit" | "crop" | "auto";
  width?: number;
  height?: number;
  zoom?: number;
  opacity?: number;
  ratio?: `${string}:${string}`;
  gravity?: "face" | "faces";
  quality?: "auto" | number;
  format?: "auto" | "svg" | "gif" | "webp" | "jpg" | "ico" | "pdf" | "png";
  rotate?: number | "auto_right" | "auto_left" | "vflip" | "hflip" | "ignore";
}

export function cloudinary(url?: string, props: CloudinaryProps = {}): string {
  if (!url) return "";
  // contentful sends protocol-relative image src
  if (url.startsWith("//")) url = "https:" + url;
  // does not work on local images
  if (!url.startsWith("http")) return url;
  // disable for storybook
  if (
    url.includes(".chromatic.") ||
    url.includes("hbs-design-system.netlify.app") ||
    url.includes("designsystem.hbs.edu")
  ) {
    return url;
  }

  const src = encodeURIComponent(url);

  const format = "f_" + (props.format || "webp");

  let crop = "c_fill";
  if (props.crop) crop = `c_${props.crop}`;

  let quality = "q_auto";
  if (props.quality) quality = `q_${props.quality}`;

  const transforms: Array<string> = [quality];

  if (props.width || props.height || props.ratio) transforms.push(crop);

  if (props.width) transforms.push(`w_${props.width}`);
  if (props.height) transforms.push(`h_${props.height}`);
  if (props.rotate) transforms.push(`a_${props.rotate}`);
  if (props.ratio) transforms.push(`ar_${props.ratio}`);
  if (props.gravity) transforms.push(`g_${props.gravity}`);
  if (props.opacity) transforms.push(`o_${props.opacity}`);
  if (props.zoom) transforms.push(`z_${props.zoom}`);

  return `https://cloudinary.hbs.edu/hbsit/image/fetch/${transforms.join(
    ",",
  )}/${format}/${src}`;
}
